<template>
  <b-table
    small
    bordered
    head-variant="light"
    :fields="tableFields"
    :items="flextimeInfos"
    sort-by="year"
    :busy="tableBusy"
  >
    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner class="align-middle mr-2" />
        <strong>{{ $t('general.table.loading') }}</strong>
      </div>
    </template>
    <template v-slot:cell(flextimeBalanceCurrentYear)="row">
      {{ row.value }}
      <span
        v-if="row.item.year === dates.currentYear"
        class="ml-1"
        v-b-tooltip.right
        :title="$t('timesheet.tooltips.overtime-year')"
      >
        <b-icon icon="question-circle" variant="secondary" scale="0.9" />
      </span>
    </template>
    <template v-slot:cell(annualClosingStatus)="row">
      <b-button
        v-if="closingStatus(row.item) === 'OPEN'"
        @click="closeYear(row.item.year)"
        :disabled="disableStatus(row.item)"
        size="xs"
        class="mr-1"
        variant="success"
      >
        <b-icon icon="unlock-fill" aria-hidden="true" />
        {{ $t('timesheet.annualClosing.performShort') }}
      </b-button>
      <b-button
        v-else-if="closingStatus(row.item) === 'CLOSED'"
        @click="openYear(row.item.year)"
        :disabled="disableStatus(row.item)"
        size="xs"
        class="mr-1"
        variant="warning"
      >
        <b-icon icon="lock-fill" aria-hidden="true" />
        {{ $t('timesheet.annualClosing.withdrawShort') }}
      </b-button>
    </template>
  </b-table>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { TimesheetFunctionsMixin } from '@/components/timesheet/TimesheetFunctionsMixin'

export default {
  name: 'TimesheetFlextimeInfoManagement',
  props: {
    userId: {
      required: false,
      type: Number,
      default: null
    }
  },
  mixins: [ApiMixin, NotificationMixin, TimesheetFunctionsMixin],
  data() {
    return {
      tableBusy: false,
      flextimeInfos: []
    }
  },
  computed: {
    tableFields() {
      return [
        {
          key: 'year',
          label: this.$t('datetime.year.year'),
          class: 'text-center',
          sortable: false
        },
        {
          key: 'flextimeBalancePreviousYear',
          label: this.$t('timesheet.flextime-balance-last-year'),
          class: 'text-center',
          formatter: 'formatPossiblyNegativeMinutes',
          sortable: false
        },
        {
          key: 'flextimeBalanceCurrentYear',
          label: this.$t('timesheet.overtime-current-year'),
          class: 'text-center',
          formatter: 'formatPossiblyNegativeMinutes',
          sortable: false
        },
        {
          key: 'flextimeCorrectionsCurrentYear',
          label: this.$t('timesheet.flextime-corrections'),
          class: 'text-center',
          formatter: 'formatPossiblyNegativeMinutes',
          sortable: false
        },
        {
          key: 'flextimeBalanceOverall',
          label: this.$t('timesheet.flextime-balance-total'),
          class: 'text-center',
          formatter: 'formatPossiblyNegativeMinutes',
          sortable: false
        },
        {
          key: 'annualClosingStatus',
          label: this.$t('timesheet.annualClosing.title'),
          class: 'text-center',
          width: '120px',
          sortable: false
        }
      ]
    }
  },
  mounted() {
    this.loadFlextimeInformation()
  },
  methods: {
    formatPossiblyNegativeMinutes(minutes) {
      if (minutes === null) {
        return ''
      }
      let isNegative = minutes < 0
      let minutesPositive = Math.abs(minutes)
      return (isNegative ? '-' : '') + this.formatMinutes(minutesPositive)
    },
    loadFlextimeInformation() {
      let self = this
      self.tableBusy = true
      this.getRequest(
        `/users/${this.userId}/flextime_infos/all?limitToday=true`,
        new RequestConfig().onSuccess(res => {
          self.flextimeInfos = res.data
          self.tableBusy = false
        })
      )
    },
    closingStatus(item) {
      return item.id !== null ? 'CLOSED' : 'OPEN'
    },
    disableStatus(item) {
      if (item.id !== null) {
        for (let flextimeInfo of this.flextimeInfos) {
          if (flextimeInfo.year > item.year && flextimeInfo.id !== null) {
            return true
          }
        }
        return false
      } else {
        for (let flextimeInfo of this.flextimeInfos) {
          if (flextimeInfo.year < item.year && flextimeInfo.id === null) {
            return true
          }
        }
        return false
      }
    },
    closeYear(year) {
      let self = this
      this.postRequest(
        `/users/${this.userId}/flextime_infos/close_year?year=${year}`,
        {},
        new RequestConfig().onSuccess(() => {
          self.flextimeInfos.length = 0
          self.loadFlextimeInformation()
        })
      )
    },
    openYear(year) {
      let self = this
      this.postRequest(
        `/users/${this.userId}/flextime_infos/reopen_year?year=${year}`,
        {},
        new RequestConfig().onSuccess(() => {
          self.flextimeInfos.length = 0
          self.loadFlextimeInformation()
        })
      )
    }
  }
}
</script>

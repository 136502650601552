import { i18n } from '@/plugins/i18n'

export const UserUtil = {
  sortByFullName(userList) {
    return userList.sort((a, b) => a.fullName.localeCompare(b.fullName))
  },
  sortByStatusAndFullName(userList) {
    return userList.sort((a, b) => {
      // Sort by status first
      if (a.status > b.status) return 1
      if (a.status < b.status) return -1
      // then by fullName
      if (a.fullName > b.fullName) return 1
      if (a.fullName < b.fullName) return -1
    })
  },
  getSelectOptsForUserList(userList) {
    return this.sortByFullName(userList).map(entry => {
      return { value: entry, text: entry.fullName }
    })
  },
  getSelectOptsForUserListGroupedByStatus(userList) {
    let active = []
    let inactive = []
    userList.forEach(user => {
      if (user.status === 'ACTIVE') {
        active.push({ value: user, text: user.fullName })
      } else {
        inactive.push({ value: user, text: user.fullName })
      }
    })
    active.sort((a, b) => a.text.localeCompare(b.text))
    inactive.sort((a, b) => a.text.localeCompare(b.text))
    return [
      {
        label: i18n.t('user.active-users'),
        options: active
      },
      {
        label: i18n.t('user.inactive-users'),
        options: inactive
      }
    ]
  }
}

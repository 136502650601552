<template>
  <b-container>
    <b-card no-body>
      <b-tabs pills card vertical>
        <b-tab
          v-for="user in userList"
          :title="user.fullName"
          :key="user.id"
          :title-link-class="linkClass(user.status)"
          lazy
        >
          <h4 class="text-center mb-4">{{ $t('timesheet.current-overtime') }}</h4>
          <TimesheetOverview :user-id="user.id" />

          <h4 class="text-center mt-5 mb-4">{{ $t('timesheet.flextime-account') }}</h4>
          <TimesheetFlextimeOverview :user-id="user.id" class="mb-4" :key="flextimeCorrectionsChanged" />
          <TimesheetFlextimeInfoManagement :user-id="user.id" :key="flextimeCorrectionsChanged" />

          <h4 class="text-center mt-5 mb-4">{{ $t('timesheet.flextime-corrections') }}</h4>
          <TimesheetFlextimeCorrectionManagement :user-id="user.id" :show-actions="true" />

          <h4 class="text-center mt-5 mb-4">{{ $t('timesheet.monthly-view') }}</h4>
          <TimesheetMonthChart :user-id="user.id" />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { UserUtil } from '@/util/UserUtil'
import TimesheetOverview from '@/components/timesheet/TimesheetOverview'
import TimesheetMonthChart from '@/components/timesheet/TimesheetMonthChart'
import TimesheetFlextimeOverview from '@/components/timesheet/TimesheetFlextimeOverview'
import TimesheetFlextimeInfoManagement from '@/components/timesheet/TimesheetFlextimeInfoManagement'
import TimesheetFlextimeCorrectionManagement from '@/components/timesheet/TimesheetFlextimeCorrectionManagement'
import { LocalDate } from '@/util/LocalDateTimeFormatter'
import { EventBus } from '@/eventBus'

export default {
  name: 'ManageTimesheets',
  mixins: [ApiMixin],
  components: {
    TimesheetOverview,
    TimesheetMonthChart,
    TimesheetFlextimeOverview,
    TimesheetFlextimeInfoManagement,
    TimesheetFlextimeCorrectionManagement
  },
  data() {
    return {
      userList: [],
      flextimeCorrectionsChanged: LocalDate.getISOTimestamp()
    }
  },
  created() {
    this.getAllActiveUsers()
    // Listen to events from global EventBus
    // https://v3.vuejs.org/guide/migration/events-api.html#_2-x-syntax
    EventBus.$on('flextime-corrections-updated', this.updateFlextimeCorrectionsChangedTimestamp)
  },
  beforeDestroy() {
    // Destroy Event listener from global EventBus
    // https://v3.vuejs.org/guide/migration/events-api.html#_2-x-syntax
    EventBus.$off('flextime-corrections-updated')
  },
  methods: {
    getAllActiveUsers() {
      let self = this
      this.getRequest(
        '/users',
        new RequestConfig().onSuccess(res => {
          self.userList = UserUtil.sortByStatusAndFullName(res.data)
        })
      )
    },
    updateFlextimeCorrectionsChangedTimestamp(timestamp) {
      this.flextimeCorrectionsChanged = timestamp
    },
    linkClass(userStatus) {
      return userStatus === 'INACTIVE' ? ['bg-light', 'text-secondary'] : ['bg-light', 'text-primary']
    }
  }
}
</script>
